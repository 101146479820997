import { type FC } from 'react'
import cls from 'clsx'
import { useTheme } from '@mui/material'
import { Typography } from '@ntpkunity/controls'
import { MultiColorCardStyle } from './multiColorCardStyle'

interface MultiColorCardProps {
  title?: string
  text?: string
  purple?: boolean
  green?: boolean
  orange?: boolean
  pink?: boolean
}

export const MultiColorCard: FC<MultiColorCardProps> = ({ title, text, purple, green, orange, pink }) => {
  const theme = useTheme()
  return (
        <MultiColorCardStyle theme={theme} className={cls({ 'multi-color-card': true, 'purple-bg': purple, 'green-bg': green, 'orange-bg': orange, 'pink-bg': pink })}>
            <Typography theme={theme} variant='subtitle1' component='div' className='title' mb={2}>{title}</Typography>
            <Typography theme={theme} variant='body2' component='p' className='text'>{text}</Typography>
        </MultiColorCardStyle>
  )
}
