import { styled } from '@mui/material/styles'
import { Box, type IBoxProps } from '@ntpkunity/controls'

export const CardStyle = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.card': {
    width: '100%',
    padding: 20,
    borderRadius: 16,
    backgroundColor: theme.palette.common.white,
    '.text': {
      color: theme.palette.grey[600]
    }
  }
}))
