import { styled } from '@mui/material'
import { Box, type IBoxProps } from '@ntpkunity/controls'

export const PrincipleSectionStyle = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.principle-section': {
    width: '100%',
    padding: '120px 0',
    '.icons-detail-wrap': {
      '.icons-detail-item': {
        minHeight: 290,
        padding: '0 55px',
        borderLeft: '1px solid' + theme.palette.divider,
        img: {
          marginBottom: 40
        },
        '.title': {
          minHeight: 48
        },
        '.desc': {
          color: theme.palette.grey[600]
        },
        '&:first-child': {
          borderLeft: '0 solid transparent'
        }
      },
      '.react-multi-carousel-list': {
        padding: 0,
        '.react-multi-carousel-track': {
          '.react-multi-carousel-item': {
            borderLeft: '1px solid' + theme.palette.divider
          }
        }
      },
      '.react-multi-carousel-dot-list': {
        bottom: '-45px'
      },
      '.carousel-button-group': {
        display: 'none'
      },
      '.react-multi-carousel-dot': {
        button: {
          border: '0 solid transparent',
          backgroundColor: '#F0F0FD',
          height: 7.5,
          width: 7.5
        },
        '&.react-multi-carousel-dot--active': {
          button: {
            backgroundColor: theme.palette.primary.main
          }
        }
      }
    },
    [theme.breakpoints.down('md')]: {
      padding: '40px 0',
      '.heading': {
        fontSize: theme.typography.h3.fontSize,
        lineHeight: theme.typography.h3.lineHeight,
        marginBottom: 58
      },
      '.icons-detail-wrap': {
        '.icons-detail-item': {
          minHeight: 0,
          width: '100%',
          display: 'inline-block',
          borderLeft: '0 solid transparent',
          padding: '0 0 24px 0',
          img: {
            marginBottom: 24
          },
          '.title': {
            minHeight: 0
          }
        },
        '.react-multi-carousel-list': {
          padding: 0
        }
      }
    }
  }
}))
