import { type FC } from 'react'
import { useTheme } from '@mui/material'
import { Typography } from '@ntpkunity/controls'
import { CardStyle } from './cardStyle'

interface CardProps {
  title?: string
  text?: string
}

export const Card: FC<CardProps> = ({ title, text }) => {
  const theme = useTheme()
  return (
        <CardStyle theme={theme} className="card">
            <Typography theme={theme} variant='subtitle1' component='div' className='title' mb={1}>{title}</Typography>
            <Typography theme={theme} variant='body1' component='p' className='text'>{text}</Typography>
        </CardStyle>
  )
}
