import { styled } from '@mui/material'
import { Box, type IBoxProps } from '@ntpkunity/controls'

export const JourneySectionStyle = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.journey-section': {
    width: '100%',
    padding: '80px 120px',
    backgroundColor: '#F6F9FE',
    position: 'relative',
    bottom: -80,
    '.card': {
      minHeight: 170
    },
    [theme.breakpoints.down('sm')]: {
      padding: '40px 0',
      '.heading-h1': {
        fontSize: theme.typography.h3.fontSize,
        lineHeight: theme.typography.h3.lineHeight
      },
      '.heading-h2': {
        fontSize: theme.typography.h4.fontSize,
        lineHeight: theme.typography.h4.lineHeight
      },
      '.card': {
        minHeight: 'auto'
      }
    }
  }
}))
