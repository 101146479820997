import { styled } from '@mui/material/styles'
import { Box, type IBoxProps } from '@ntpkunity/controls'
import BannerImage from '@shared/assets/images/ai-banner-bg.jpg'

export const BannerWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.ai-banner-wrap': {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    backgroundPosition: 'center center',
    width: '100%',
    height: 740,
    minHeight: 530,
    display: 'flex',
    alignItems: 'center',
    '.text-h2': {
      fontSize: theme.typography.h1.fontSize,
      fontWeight: theme.typography.fontWeightRegular,
      lineHeight: '62px',
      marginBottom: 42,
      span: {
        fontSize: 60,
        fontWeight: theme.typography.fontWeightBold
      },
      [theme.breakpoints.down('md')]: {
        fontSize: theme.typography.h2.fontSize,
        lineHeight: theme.typography.h2.lineHeight,
        span: {
          fontSize: theme.typography.h1.fontSize,
          lineHeight: theme.typography.h1.lineHeight
        }
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.typography.h3.fontSize,
        lineHeight: theme.typography.h3.lineHeight,
        span: {
          fontSize: theme.typography.h2.fontSize,
          lineHeight: theme.typography.h2.lineHeight
        }
      }
    },
    '.btn.btn-primary': {
      backgroundColor: theme.palette.common.white,
      borderColor: theme.palette.primary.main,
      color: theme.palette.primary.main,
      width: 188,

      '&:hover': {
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main
      }
    },
    [theme.breakpoints.down('md')]: {
      textAlign: 'center',
      borderRadius: 16,
      '.custom-container': {
        position: 'relative',
        top: '15%'
      }
    },
    [theme.breakpoints.down('sm')]: {
      minHeight: 530,
      borderRadius: 16,
      '.custom-container': {
        top: '25%'
      }
    },
    '.ai-banner-content': {
      maxWidth: 755,
      '@media(min-width: 650px) and (max-width: 960px)': {
        paddingTop: 70,
        maxWidth: 440,
        width: '100%',
        margin: 'auto'
      }
    }
  }
}))
