import { styled } from '@mui/material'
import { Box, type IBoxProps } from '@ntpkunity/controls'
import { CustomColors } from '@shared/theme'

export const AiWrapNew = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.ai-wrap-new': {
    '.contact-bar': {
      backgroundColor: CustomColors.greyLight,
      borderRadius: 16
    }
  }
}))
