import { createTheme } from '@mui/material'
import { unityTheme } from '@ntpkunity/controls'

export const CustomColors = {
  gradient: 'linear-gradient(90deg, #0033FD 0%, #00E4D0 100%)', // Gradient
  greyLight: '#F5F5F7',
  purple: '#8D75E6',
  purpleLight: '#F3ECF7',
  green: '#1F7A57',
  greenLight: '#E9F3E7',
  orange: '#F08146',
  orangeLight: '#FBE4D7',
  pink: '#A54B7A',
  pinkLight: '#FEEBEE'
}

export const lightTheme = createTheme({
  ...unityTheme,
  palette: {
    ...unityTheme.palette,
    primary: { main: '#0033FD' },
    secondary: { main: '#DDDDDD' }
  }
})

export const marketplaceTheme = createTheme({
  ...unityTheme,
  palette: {
    ...unityTheme.palette,
    primary: { main: '#0033FD' }
  }
})
