import { useTheme } from '@mui/material'
import {
  Icon,
  Box,
  Container,
  Grid,
  Typography,
  Tooltip
} from '@ntpkunity/controls'
import { APP_ROUTES } from '@router/path'
import { AppFooter } from './footerStyle'
import { NavLink } from 'react-router-dom'
import dayjs from 'dayjs'
import FooterLogo from '../../../shared/assets/images/appex-now-footer-logo.svg'
import { className } from '@shared/index'
import { type FC } from 'react'

export const Footer: FC = () => {
  const theme = useTheme()

  return (
    <AppFooter className="app-footer" theme={theme} component="footer">
      <Container theme={theme} maxWidth="lg" className="custom-container">
        <Grid theme={theme} container item spacing={3}>
          <Grid theme={theme} item xs={12} md={4}>
            <Box theme={theme} className="our-address">
              <Box theme={theme} className="footer-title footer-logo">
                <img src={FooterLogo} alt="Appex Now" />
              </Box>
              <Box theme={theme} className="text">
                <Typography
                  theme={theme}
                  variant="body2"
                  component="span"
                  className="text-body2"
                >
                  Corporate Headquarters, 16000 Ventura Blvd,
                  <br />
                  Suite 770, Encino, CA 91436, USA
                </Typography>
              </Box>
              <Box theme={theme} className="links" mt={1}>
                <ul>
                  <li>
                    <a
                      className="text-primary"
                      href={`mailto:${process.env.SUPPORT_EMAIL as string}`}
                    >
                      {process.env.SUPPORT_EMAIL}
                    </a>
                  </li>
                  <li className="text-body2">+1 818 222 9195</li>
                </ul>
              </Box>
              <Box theme={theme} className="copy" mt={{ xs: 3, lg: 4 }}>
                <Typography
                  theme={theme}
                  variant="body2"
                  component="span"
                  className="text-body2"
                >
                  Copyrights &copy; {dayjs().year()} NETSOL Technologies. <br />
                  All Rights Reserved.
                </Typography>
              </Box>
              <Box theme={theme} className="social" mt={{ xs: 3, lg: 4 }}>
                <ul>
                  <li>
                    <a
                      href="https://www.linkedin.com/showcase/netsol-flex/?viewAsMember=true"
                      target="_blank"
                      className="social-link link-in"
                      rel="noreferrer"
                    >
                      <Icon name="LinkedInIcon" />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://twitter.com/AppexNow"
                      target="_blank"
                      className="social-link link-twt"
                      rel="noreferrer"
                    >
                      <Icon name="TwitterIcon" />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.youtube.com/channel/UCqTySRaTv5PVWeiZfeiyCaA"
                      target="_blank"
                      className="social-link link-yt"
                      rel="noreferrer"
                    >
                      <Icon name="YoutubeIcon" />
                    </a>
                  </li>
                  <li>
                    <a
                      href={`${process.env.MEDIUM_CHANNEL as string}`}
                      target="_blank"
                      className="social-link link-medium"
                      rel="noreferrer"
                    >
                      <Icon name="MediumIcon" />
                    </a>
                  </li>
                </ul>
              </Box>
            </Box>
          </Grid>
          <Grid theme={theme} item xs={12} md={8}>
            <Grid theme={theme} container item spacing={3}>
              <Grid theme={theme} item xs={6} sm={3} md={3} lg={3}>
                <Box theme={theme} className="general">
                  <Box theme={theme} className="footer-title">
                    <Typography
                      theme={theme}
                      variant="subtitle1"
                      component="p"
                      data-testid="general"
                    >
                      General
                    </Typography>
                  </Box>
                  <Box theme={theme} className="links">
                    <ul>
                      <li>
                        <NavLink
                          to={`${APP_ROUTES.PRODUCTS}`}
                          style={{ textDecoration: 'none' }}
                        >
                          Products
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to={`${APP_ROUTES.AI_LANDING_PAGE_NEW}`}
                          style={{ textDecoration: 'none' }}
                        >
                          AI Platform
                        </NavLink>
                      </li>
                      <li>
                        <Tooltip
                          theme={theme}
                          title="Coming soon"
                          placement="bottom-start"
                        >
                          <a href="javascript:void(0)">Add-Ons</a>
                        </Tooltip>
                      </li>
                      <li>
                        <Tooltip
                          theme={theme}
                          title="Coming soon"
                          placement="bottom-start"
                        >
                          <a href="javascript:void(0)">Services</a>
                        </Tooltip>
                      </li>
                      <li>
                        <Tooltip
                          theme={theme}
                          title="Coming soon"
                          placement="bottom-start"
                        >
                          <a href="javascript:void(0)">Bundles</a>
                        </Tooltip>
                      </li>
                      <li>
                        <NavLink
                          to={`${APP_ROUTES.NEW_HOME_PAGE}#education`}
                          style={{ textDecoration: 'none' }}
                        >
                          Education
                        </NavLink>
                      </li>
                      {/* <li>
                        <a
                          className="nav-link"
                          href={`${process.env.FLEX_MARKETING}#pricing`}
                          target="_blank"
                          style={{ textDecoration: "none" }}
                        >
                          Pricing
                        </a>
                      </li> */}
                    </ul>
                  </Box>
                </Box>
              </Grid>
              <Grid theme={theme} item xs={6} sm={3} md={3} lg={3}>
                <Box theme={theme} className="more">
                  <Box theme={theme} className="footer-title">
                    <Typography theme={theme} variant="subtitle1" component="p">
                      Developers
                    </Typography>
                  </Box>
                  <Box theme={theme} className="links">
                    <ul>
                      <li>
                        <Tooltip
                          theme={theme}
                          title="Coming soon"
                          placement="bottom-start"
                        >
                          <a href="javascript:void(0)">API Status</a>
                        </Tooltip>
                      </li>
                      <li>
                        <Tooltip
                          theme={theme}
                          title="Coming soon"
                          placement="bottom-start"
                        >
                          <a href="javascript:void(0)">Change Log</a>
                        </Tooltip>
                      </li>
                      <li>
                        <a
                          className={className.footerDocumentationBtn}
                          href={process.env.PRODUCT_DOCS}
                          target="_blank"
                          rel="noreferrer"
                        >
                          Product Docs
                        </a>
                      </li>
                    </ul>
                  </Box>
                </Box>
              </Grid>
              <Grid theme={theme} item xs={6} sm={3} md={3} lg={3}>
                <Box theme={theme} className="more">
                  <Box theme={theme} className="footer-title">
                    <Typography theme={theme} variant="subtitle1" component="p">
                      Resources
                    </Typography>
                  </Box>
                  <Box theme={theme} className="links">
                    <ul>
                      <li>
                        <a
                          href="https://medium.com/@appexnow01/apis-in-financial-services-unlocking-the-future-with-api-first-solutions-45d4da1c60ed"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Articles
                        </a>
                      </li>
                      <li>
                        <NavLink
                          to={`${APP_ROUTES.BLOGS}`}
                          style={{ textDecoration: 'none' }}
                        >
                          Blog
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to={`${APP_ROUTES.WEBINAR}`}
                          style={{ textDecoration: 'none' }}
                        >
                          Webinar
                        </NavLink>
                      </li>
                      <li>
                        <Tooltip
                          theme={theme}
                          title="Coming soon"
                          placement="bottom-start"
                        >
                          <a href="javascript:void(0)">Case Studies</a>
                        </Tooltip>
                      </li>
                      <li>
                        <Tooltip
                          theme={theme}
                          title="Coming soon"
                          placement="bottom-start"
                        >
                          <a href="javascript:void(0)">Community</a>
                        </Tooltip>
                      </li>
                      <li>
                        <NavLink
                          to={`${APP_ROUTES.NEW_HOME_PAGE}#faqs`}
                          style={{ textDecoration: 'none' }}
                        >
                          FAQs
                        </NavLink>
                      </li>
                      {/* <li>
                        <Tooltip
                          theme={theme}
                          title="Coming soon"
                          placement="bottom-start"
                        >
                          <a href="javascript:void(0)">Help Center</a>
                        </Tooltip>
                      </li> */}
                      <li>
                        <Tooltip
                          theme={theme}
                          title="Coming soon"
                          placement="bottom-start"
                        >
                          <a href="javascript:void(0)">Security</a>
                        </Tooltip>
                      </li>
                      <li>
                        <a
                          className={className.footerSupportBtn}
                          href={process.env.SUPPORT_SERVICE}
                          target="_blank"
                          rel="noreferrer"
                        >
                          Support
                        </a>
                      </li>
                    </ul>
                  </Box>
                </Box>
              </Grid>
              <Grid theme={theme} item xs={6} sm={3} md={3} lg={3}>
                <Box theme={theme} className="more">
                  <Box theme={theme} className="footer-title">
                    <Typography theme={theme} variant="subtitle1" component="p">
                      Company
                    </Typography>
                  </Box>
                  <Box theme={theme} className="links">
                    <ul>
                      <li>
                        <Tooltip
                          theme={theme}
                          title="Coming soon"
                          placement="bottom-start"
                        >
                          <a href="javascript:void(0)">About</a>
                        </Tooltip>
                      </li>
                      <li>
                        <a
                          className={className.footerDocumentationBtn}
                          href={
                            'https://share-eu1.hsforms.com/1f7QJqMfvRlWZErMWhZmeFgfchur'
                          }
                          target="_blank"
                          rel="noreferrer"
                        >
                          Referral Program
                          <Box theme={theme} className="badge" sx={{ ml: 1 }}>
                            NEW
                          </Box>
                        </a>
                      </li>
                      <li>
                        <Tooltip
                          theme={theme}
                          title="Coming soon"
                          placement="bottom-start"
                        >
                          <a href="javascript:void(0)">Careers</a>
                        </Tooltip>
                      </li>
                      <li>
                        <NavLink
                          className={className.footerContactBtn}
                          to={`${APP_ROUTES.CONTACT_US}`}
                          style={{ textDecoration: 'none' }}
                        >
                          Contact
                        </NavLink>
                      </li>
                      {/* <li>
                        <Tooltip
                          theme={theme}
                          title="Coming soon"
                          placement="bottom-start"
                        >
                          <a href="javascript:void(0)">Copyright Notice</a>
                        </Tooltip>
                      </li> */}
                      {
                        <li>
                          <NavLink
                            to={`${APP_ROUTES.PRIVACY_POLICY}`}
                            style={{ textDecoration: 'none' }}
                          >
                            Privacy Policy
                          </NavLink>
                        </li>
                      }
                      <li>
                        <NavLink
                          to={`${APP_ROUTES.TERMS_OF_SERVICES}`}
                          style={{ textDecoration: 'none' }}
                        >
                          Terms & Conditions
                        </NavLink>
                      </li>
                    </ul>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Grid>
          {/* <Grid theme={theme} item xs={12} sm={6} lg={3}>
            <Box theme={theme} className="footer-about">
              <Box theme={theme} className="footer-title">
                <Typography theme={theme} variant="subtitle1" component="p">
                  Offices
                </Typography>
              </Box>
              <Box theme={theme} className="links">
                <ul>
                  {offices?.map((officeInfo) => (
                    <li key={officeInfo}>
                      <a href="javascript:void(0)">{officeInfo}</a>
                    </li>
                  ))}
                </ul>
              </Box>
            </Box>
          </Grid> */}
        </Grid>
      </Container>
    </AppFooter>
  )
}

Footer.propTypes = {}
