import { styled } from '@mui/material/styles'
import { Box, type IBoxProps } from '@ntpkunity/controls'
import { CustomColors } from '@shared/theme'

export const GreyCardStyle = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.grey-card': {
    width: '100%',
    padding: 40,
    borderRadius: 16,
    backgroundColor: CustomColors.greyLight,
    '.icon': {
      width: 72,
      height: 72,
      borderRadius: 72,
      clear: 'both',
      marginBottom: 16,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: theme.palette.primary.main
    },
    '.text': {
      color: theme.palette.grey[600]
    },
    [theme.breakpoints.down('sm')]: {
      padding: 32
    }
  }
}))
