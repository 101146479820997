import { type FC } from 'react'
import { useTheme } from '@mui/material'
import { Box, Typography } from '@ntpkunity/controls'
import { PrincipleSectionStyle } from './principleSectionStyle'
import { ResponsiveCarousel, type ResponsiveType } from '@shared/components/ResponsiveCarousel/ResponsiveCarousel'
import AiTagIcon2 from '@shared/assets/images/ai-tag-icon-2.svg'
import AiGraphIcon from '@shared/assets/images/ai-graph-icon.svg'
import AiLayoutIcon from '@shared/assets/images/ai-layout-icon.svg'
import AiFeedbackIcon from '@shared/assets/images/feedback-icon.svg'
import AiScaleIcon from '@shared/assets/images/scale-icon.svg'
import AiTrustIcon from '@shared/assets/images/trust.svg'

export const responsive = ({
  itemXl,
  itemLg,
  itemMd,
  itemSm,
  itemXs
}): ResponsiveType => ({
  xl: {
    breakpoint: { max: 4000, min: 1920 },
    items: itemXl
  },
  lg: {
    breakpoint: { max: 1920, min: 1201 },
    items: itemLg
  },
  md: {
    breakpoint: { max: 1200, min: 992 },
    items: itemMd,
    partialVisibilityGutter: 16
  },
  sm: {
    breakpoint: { max: 991, min: 576 },
    items: itemSm,
    partialVisibilityGutter: 16
  },
  xs: {
    breakpoint: { max: 575, min: 0 },
    items: itemXs,
    partialVisibilityGutter: 16
  }
})

export const PrincipleSection: FC = () => {
  const theme = useTheme()
  return (
    <PrincipleSectionStyle theme={theme} className='principle-section'>
      <Typography theme={theme} variant='h1' component='h1' textAlign={'center'} className='heading' mb={{ md: 6, sm: 4, xs: 4 }}>
        Platform Design Principles
      </Typography>
      <Box theme={theme} className='icons-detail-wrap' textAlign={'center'} justifyContent={'center'} display={{ xl: 'none', lg: 'none', md: 'none', sm: 'none', xs: 'none' }}>
        <Box theme={theme} className='icons-detail-item'>
          <img src={AiTagIcon2} alt='Deployment-agnostic and model-agnostic' title='Deployment-agnostic and model-agnostic' />
          <Typography theme={theme} variant='h4' className='title' component='h4' mb={1}>
            Deployment-agnostic and model-agnostic
          </Typography>
          <Typography theme={theme} variant='body1' component='p' className='desc'>
            Lorem ipsum dolor sit amet, consectetur adipis elit. Sit enim nec, proin faucibus nibh et sagittis a. Lacinia purus ac amet.
          </Typography>
        </Box>
        <Box theme={theme} className='icons-detail-item'>
          <img src={AiGraphIcon} alt='Powered by Transcend Orchestration Framework' title='Powered by Transcend Orchestration Framework' />
          <Typography theme={theme} variant='h4' className='title' component='h4' mb={1}>
            Powered by Transcend Orchestration Framework
          </Typography>
          <Typography theme={theme} variant='body1' component='p' className='desc'>
            Lorem ipsum dolor sit amet, consectetur adipis elit. Sit enim nec, proin faucibus nibh et sagittis a. Lacinia purus ac amet.
          </Typography>
        </Box>
        <Box theme={theme} className='icons-detail-item'>
          <img src={AiLayoutIcon} alt='Explainability & transparency' title='Explainability & transparency' />
          <Typography theme={theme} variant='h4' className='title' component='h4' mb={1}>
            Explainability & transparency
          </Typography>
          <Typography theme={theme} variant='body1' component='p' className='desc'>
            Lorem ipsum dolor sit amet, consectetur adipis elit. Sit enim nec, proin faucibus nibh et sagittis a. Lacinia purus ac amet.
          </Typography>
        </Box>
      </Box>
      <Box theme={theme} className='icons-detail-wrap' position={'relative'} textAlign={'center'} display={{ xl: 'block', lg: 'block', md: 'block', sm: 'block', xs: 'block' }}>
        <ResponsiveCarousel
          showDots
          autoPlay={false}
          responsive={responsive({
            itemXl: 3,
            itemLg: 3,
            itemMd: 2,
            itemSm: 1,
            itemXs: 1
          })}
        >
          <Box theme={theme} className='icons-detail-item'>
            <img src={AiTagIcon2} alt='Deployment-agnostic and model-agnostic' title='Deployment-agnostic and model-agnostic' />
            <Typography theme={theme} variant='h4' className='title' component='h4' mb={1}>
              Deployment-agnostic and model-agnostic
            </Typography>
            <Typography theme={theme} variant='body1' component='p' className='desc'>
              To have adaptable, scalable systems that integrate well with existing tools and platforms through API-first, deployment-agnostic and extensible frameworks.
            </Typography>
          </Box>
          <Box theme={theme} className='icons-detail-item'>
            <img src={AiGraphIcon} alt='Powered by Transcend Orchestration Framework' title='Powered by Transcend Orchestration Framework' />
            <Typography theme={theme} variant='h4' className='title' component='h4' mb={1}>
              Powered by Transcend Orchestration Framework
            </Typography>
            <Typography theme={theme} variant='body1' component='p' className='desc'>
              To facilitate rapid development by automatically integrating the relevant data, logic, workflows and action components into a modern, AI-first platform.
            </Typography>
          </Box>
          <Box theme={theme} className='icons-detail-item'>
            <img src={AiLayoutIcon} alt='Explainability & transparency' title='Explainability & transparency' />
            <Typography theme={theme} variant='h4' className='title' component='h4' mb={1}>
              Explainability & transparency
            </Typography>
            <Typography theme={theme} variant='body1' component='p' className='desc'>
              To foster trust and reliability by making AI models and outputs clear and understandable.
            </Typography>
          </Box>
          <Box theme={theme} className='icons-detail-item'>
            <img src={AiTrustIcon} alt='Explainability & transparency' title='Explainability & transparency' />
            <Typography theme={theme} variant='h4' className='title' component='h4' mb={1}>
              Trust
            </Typography>
            <Typography theme={theme} variant='body1' component='p' className='desc'>
              To promote fairness, accountability, and transparency across all stages of an AI system lifecycle.
            </Typography>
          </Box>
          <Box theme={theme} className='icons-detail-item'>
            <img src={AiScaleIcon} alt='Explainability & transparency' title='Explainability & transparency' />
            <Typography theme={theme} variant='h4' className='title' component='h4' mb={1}>
              Scalable & sustainable
            </Typography>
            <Typography theme={theme} variant='body1' component='p' className='desc'>
              To build systems that are efficient, scalable, and environmentally conscious while using modern software engineering practices.
            </Typography>
          </Box>
          <Box theme={theme} className='icons-detail-item'>
            <img src={AiFeedbackIcon} alt='Explainability & transparency' title='Explainability & transparency' />
            <Typography theme={theme} variant='h4' className='title' component='h4' mb={1}>
              Human feedback
            </Typography>
            <Typography theme={theme} variant='body1' component='p' className='desc'>
              To inculcate a dynamic system that evolves based on user feedback and iterative model improvements.
            </Typography>
          </Box>
        </ResponsiveCarousel>
      </Box>
    </PrincipleSectionStyle>
  )
}
