import { styled } from '@mui/material'
import { Box, type IBoxProps } from '@ntpkunity/controls'
import AiPurpleBg from '@shared/assets/images/ai-purple-bg.svg'
import AiBlueBg from '@shared/assets/images/ai-blue-bg.svg'
import AiGreenBg from '@shared/assets/images/ai-green-bg.svg'
import AiOrangeBg from '@shared/assets/images/ai-orange-bg.svg'
import AiPinkBg from '@shared/assets/images/ai-pink-bg.svg'

const BgBlue = '#D4DAF3'
const TextBlue = '#14148F'

const BgPurple = '#F3ECF7'
const TextPurple = '#4B359A'

const BgGreen = '#E9F3E7'
const TextGreen = '#13573D'

const BgOrange = '#FBE4D7'
const TextOrange = '#7D3813'

const BgPink = '#FEEBEE'
const TextPink = '#96223A'

const BtnWrapBg = 'rgba(0, 0, 0, 0.4)'

export const EcosystemSectionStyle = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.ecosystem-section': {
    width: '100%',
    padding: '120px 0',

    '.heading-sm': {
      fontSize: 32,
      lineHeight: '48px'
    },

    '.section-text': {
      fontWeight: theme.typography.fontWeightMedium,
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.typography.htmlFontSize,
        fontWeight: theme.typography.fontWeightRegular,
        lineHeight: '20px'
      }
    },

    '.styled-tooltip': {
      display: 'inline-block',
      [theme.breakpoints.down('md')]: {
        display: 'initial'
      }
    },

    '.link': {
      cursor: 'pointer',
      display: 'inline-block',
      textDecoration: 'underline'
    },

    '.color-wrap': {
      padding: '24px 24px 0 24px',
      borderRadius: 40,
      position: 'relative',

      '.multi-color-card': {
        backgroundColor: 'rgba(255, 255, 255, 0.80)'
      },

      '.bottom': {
        '.multi-color-card': {
          borderRadius: '16px 16px 0 0'
        }
      },

      [theme.breakpoints.down('lg')]: {
        padding: 24,
        '.bottom': {
          '.multi-color-card': {
            borderRadius: 16
          }
        }
      },

      '&.blue': {
        background: `url(${AiBlueBg})` + BgBlue,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 80,
        backgroundPosition: '110px -50px',
        color: TextBlue,
        '.link': {
          color: TextBlue
        },
        '.multi-color-card': {
          color: TextBlue,
          '.title:before': {
            backgroundColor: TextBlue
          }
        },
        [theme.breakpoints.down('md')]: {
          backgroundPosition: '89% -48px'
        }
      },

      '&.purple': {
        background: `url(${AiPurpleBg})` + BgPurple,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 685,
        backgroundPosition: '-62px -118px',
        color: TextPurple,
        '.link': {
          color: TextPurple
        },
        '.multi-color-card': {
          color: TextPurple,
          '.title:before': {
            backgroundColor: TextPurple
          }
        },
        [theme.breakpoints.down('lg')]: {
          backgroundPosition: '-140px -111px'
        },
        [theme.breakpoints.down('md')]: {
          backgroundSize: '63%',
          backgroundPosition: 'right -90px'
        },
        [theme.breakpoints.down('sm')]: {
          backgroundSize: '115%',
          backgroundPosition: 'right -100px'
        }
      },

      '&.green': {
        background: `url(${AiGreenBg})` + BgGreen,
        backgroundRepeat: 'no-repeat',
        backgroundSize: '85%',
        backgroundPosition: '41% 16%',
        color: TextGreen,
        '.link': {
          color: TextGreen
        },
        '.multi-color-card': {
          color: TextGreen,
          '.title:before': {
            backgroundColor: TextGreen
          }
        },
        [theme.breakpoints.down('lg')]: {
          backgroundSize: '120%',
          backgroundPosition: 'left -40px'
        },
        [theme.breakpoints.down('md')]: {
          backgroundSize: '155%',
          backgroundPosition: '-100% -40px'
        },
        [theme.breakpoints.down('sm')]: {
          backgroundSize: '250%',
          backgroundPosition: '-15% -60px'
        }
      },

      '&.orange': {
        background: `url(${AiOrangeBg})` + BgOrange,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 80,
        backgroundPosition: '150px -50px',
        color: TextOrange,
        '.link': {
          color: TextOrange
        },
        '.multi-color-card': {
          color: TextOrange,
          '.title:before': {
            backgroundColor: TextOrange
          }
        }
      },

      '&.pink': {
        background: `url(${AiPinkBg})` + BgPink,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 590,
        backgroundPosition: '146px -111px',
        color: TextPink,
        '.link': {
          color: TextPink
        },
        '.multi-color-card': {
          color: TextPink,
          '.title:before': {
            backgroundColor: TextPink
          }
        },
        [theme.breakpoints.down('lg')]: {
          backgroundPosition: '67px -111px'
        },
        [theme.breakpoints.down('md')]: {
          backgroundSize: '100%',
          backgroundPosition: 'right -180px'
        },
        [theme.breakpoints.down('sm')]: {
          backgroundSize: '120%',
          backgroundPosition: '-40% -106px'
        }
      },

      '.btn-wrap': {
        background: BtnWrapBg,
        position: 'absolute',
        top: 0,
        right: 0,
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 40,
        transition: 'all 0.3s',
        visibility: 'hidden',
        opacity: 0,
        '.btn': {
          width: '50%'
        },
        [theme.breakpoints.down('md')]: {
          background: 'transparent',
          visibility: 'visible',
          opacity: 1,
          position: 'relative',
          display: 'inline-block',
          height: 'auto',
          '.btn': {
            width: '100%'
          }
        }
      },
      '&:hover': {
        '.btn-wrap': {
          visibility: 'visible',
          opacity: 1
        }
      }
    },

    [theme.breakpoints.down('sm')]: {
      padding: '40px 0',
      '.heading-lg': {
        fontSize: theme.typography.h3.fontSize,
        lineHeight: theme.typography.h3.lineHeight
      },
      '.heading-sm': {
        fontSize: theme.typography.h4.fontSize,
        lineHeight: theme.typography.h4.lineHeight
      }
    }
  }
}))
