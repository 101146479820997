import { styled } from '@mui/material'
import { Box, type IBoxProps } from '@ntpkunity/controls'

export const PrincipleSectionStyle = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.principle-section': {
    width: '100%',
    padding: '120px 0',
    '.grey-card': {
      minHeight: 268
    },
    [theme.breakpoints.down('sm')]: {
      padding: '40px 0',
      '.heading': {
        fontSize: theme.typography.h3.fontSize,
        lineHeight: theme.typography.h3.lineHeight
      },
      '.grey-card': {
        minHeight: 'auto'
      }
    }
  }
}))
