import { type FC } from 'react'
import { Link, useTheme } from '@mui/material'
import { Grid, Typography, Box, Button, Tooltip } from '@ntpkunity/controls'
import { MultiColorCard } from '@shared/components'
import { EcosystemSectionStyle } from './ecosystemSectionStyle'

export const EcosystemSection: FC = () => {
  const theme = useTheme()
  const LearnMoreLink: FC = () => {
    return (
            <Tooltip theme={theme} title='Stay Tuned for Launch!' placement='right'>
                <Typography theme={theme} variant='body1' component='p' className='section-text'>
                    <Link className="link">Learn More</Link>
                </Typography>
            </Tooltip>
    )
  }
  const LearnMoreBtn: FC = () => {
    return (
        <Box theme={theme} display={{ md: 'none', sm: 'block', xs: 'block' }}>
            <Tooltip theme={theme} title='Stay Tuned for Launch!' placement='bottom'>
                <Button theme={theme} fullWidth primary text='Learn More' />
            </Tooltip>
        </Box>
    )
  }
  return (
    <EcosystemSectionStyle theme={theme} className='ecosystem-section'>
            <Typography theme={theme} variant='h1' component='h1' className='heading-lg' textAlign={'center'} mb={{ md: 12, sm: 4, xs: 4 }}>
                AI Applications Ecosystem
            </Typography>
            <Box theme={theme} display={{ md: 'flex', sm: 'inline-block' }} justifyContent={{ md: 'space-between' }} gap={2} mb={2}>
            <Box theme={theme} className='color-wrap blue' width={{ md: '50%', sm: '100%' }} mb={2}>
                    <Typography theme={theme} variant='h2' component='h2' className='heading-sm' mb={1}>
                        Deep Reasoning Research
                    </Typography>
                    <Typography theme={theme} variant='body1' component='p' className='section-text' mb={{ sm: 1, xs: 1 }}>
                    Uses AI-driven reasoning to analyse applicants and their asset to generate detailed insightful reports with live internet data
                    </Typography>
                    <Box theme={theme} display={{ md: 'block', sm: 'none', xs: 'none' }} mb={4}>
                        <LearnMoreLink />
                    </Box>
                    <Box theme={theme} display={{ sm: 'flex', xs: 'inline-block' }} justifyContent={'space-between'} gap={1} mb={{ md: 1, sm: 0, xs: 1 }}>
                        <Box theme={theme} width={{ sm: '50%', xs: '100%' }} mb={{ xs: 1 }}>
                    <MultiColorCard
                                title='Application Insight'
                                text='Aggregates applicant financial history, job stability, income trends, and liabilities to present a comprehensive risk profile and repayment capability.'
                            />
                            </Box>
                            <Box theme={theme} width={{ sm: '50%', xs: '100%' }}>
                            <MultiColorCard
                                title='Asset Insight'
                                text='Provides real-time insights into asset valuation trends, resale values, and depreciation rates to enhance risk assessment and finance structuring.'
                            />
                            </Box>
                    </Box>
                    <Box theme={theme} className='bottom' mb={{ lg: 0, md: 1, sm: 1, xs: 1 }}>
                        <MultiColorCard
                                title='SWOT Analysis'
                                text='AI-driven SWOT analysis that evaluates applicant strengths, weaknesses, opportunities, and threats by integrating financial, risk, and economic data for informed credit decisions.'
                            />
                        </Box>
                    <LearnMoreBtn />
                    </Box>
                    <Box theme={theme} className='color-wrap purple' width={{ md: '50%', sm: '100%' }}>
                    <Typography theme={theme} variant='h2' component='h2' className='heading-sm' mb={1}>
                    Intelligent Document Processing
                    </Typography>
                    <Typography theme={theme} variant='body1' component='p' className='section-text' mb={{ sm: 1, xs: 1 }}>
                    Automates document handling, including extraction, classification, and analysis, to enhance efficiency.
                    </Typography>
                    <Box theme={theme} display={{ md: 'block', sm: 'none', xs: 'none' }} mb={4}>
                        <LearnMoreLink />
                    </Box>
                    <Box theme={theme} display={{ sm: 'flex', xs: 'inline-block' }} justifyContent={'space-between'} gap={1} mb={{ lg: 0, md: 1, sm: 1, xs: 1 }}>
                    <Box theme={theme} width={{ sm: '50%', xs: '100%' }} mb={{ xs: 1 }}>
                    <MultiColorCard
                                title='Document Analysis'
                                text='Advanced AI-driven analysis that interprets extracted document data, detects anomalies, verifies compliance, and provides contextual insights for decision-making.'
                            />
                            </Box>
                            <Box theme={theme} width={{ sm: '50%', xs: '100%' }} mt={{ md: 13, sm: 0, xs: 0 }}>
                                <MultiColorCard
                                    title='Document Extraction'
                                    text='Advanced AI-driven analysis that interprets extracted document data, detects anomalies, verifies compliance, and provides contextual insights for decision-making.'
                                />
                            </Box>
                    </Box>
                    <LearnMoreBtn />
                    </Box>
            </Box>
            {/* **************************************** */}
            <Box theme={theme} className='color-wrap green' mb={2}>
            <Box theme={theme} display={{ md: 'flex', sm: 'inline-block' }} justifyContent={'space-between'} alignItems={'center'} gap={2}>
            <Box theme={theme} width={{ md: '50%', sm: '100%' }}>
                <Typography theme={theme} variant='h2' component='h2' className='heading-sm' mb={{ sm: 1, xs: 1 }}>
                        Data-Driven Decisions
                        </Typography>
                        <Typography theme={theme} variant='body1' component='p' className='section-text' mb={{ sm: 1, xs: 1 }}>
                        Machine learning models for predictive analysis and forecasting
                        </Typography>
                        <Box theme={theme} display={{ md: 'block', sm: 'none', xs: 'none' }}>
                            <LearnMoreLink />
                        </Box>
                        </Box>
                        <Box theme={theme} width={{ md: '50%', sm: '100%' }} mb={{ lg: 0, md: 1, sm: 1, xs: 1 }}>
                        <Box theme={theme} display={{ sm: 'flex', xs: 'inline-block' }} justifyContent={'space-between'} gap={1} mb={1}>
                            <Box theme={theme} width={{ sm: '50%', xs: '100%' }} mb={{ sm: 0, xs: 1 }}>
                                <MultiColorCard
                                    title='Likelihood of Defaults'
                                    text='Placerat id mauris mi viverra in risus. Hendrerit fermentum venenatis fringilla nunc justo'
                                />
                            </Box>
                            <Box theme={theme} width={{ sm: '50%', xs: '100%' }}>
                                <MultiColorCard
                                    title='Accessories Recommendations'
                                    text='Personalized recommendations for vehicle accessories, leveraging customer preferences, purchase trends, and vehicle specifications to drive upsell opportunities.'
                                />
                            </Box>
                        </Box>
                        <Box theme={theme} display={{ sm: 'flex', xs: 'inline-block' }} justifyContent={'space-between'} alignItems={'center'} gap={1} mb={1}>
                            <Box theme={theme} width={{ lg: '46%', md: '43%', sm: '50%', xs: '100%' }} mb={{ sm: 0, xs: 1 }}>
                                <MultiColorCard
                                        title='Customer Clustering'
                                        text='Placerat id mauris mi viverra in risus. Hendrerit fermentum venenatis fringilla nunc justo.'
                                    />
                            </Box>
                            <Box theme={theme} width={{ lg: '54%', md: '55%', sm: '50%', xs: '100%' }}>
                            <MultiColorCard
                                    title='F&I Product Recommendations'
                                    text='Placerat id mauris mi viverra in risus. Hendrerit fermentum venenatis fringilla'
                                />
                            </Box>
                        </Box>
                        <Box theme={theme} className='bottom'>
                        <MultiColorCard
                                    title='Business KPIs forecasting'
                                    text='Placerat id mauris mi viverra in risus. Hendrerit fermentum venenatis fringilla nunc justo.'
                                />
                        </Box>
                        </Box>
                        <LearnMoreBtn />
                        </Box>
                    </Box>
            {/* **************************************** */}
            <Box theme={theme} display={{ md: 'flex', sm: 'inline-block' }} justifyContent={'space-between'} gap={2} mb={2}>
            <Box theme={theme} className='color-wrap orange' width={{ md: '50%', sm: '100%' }} mb={{ md: 0, sm: 2, xs: 2 }}>
                    <Typography theme={theme} variant='h2' component='h2' className='heading-sm' mb={1}>
                    Rule Forge
                    </Typography>
                    <Typography theme={theme} variant='body1' component='p' className='section-text' mb={{ sm: 1, xs: 1 }}>
                    Automates document handling, including extraction classification, and analysis, to enhance efficiency.
                    </Typography>
                    <Box theme={theme} display={{ md: 'block', sm: 'none', xs: 'none' }} mb={4}>
                        <LearnMoreLink />
                    </Box>
                    <Box theme={theme} className='bottom' mb={{ lg: 0, md: 1, sm: 1, xs: 1 }}>
                    <MultiColorCard
                                title='Natural Language Business Rules Generation & Validations'
                                text='Transforms natural language inputs into structured, executable business rules without coding while ensuring accuracy through automated validation against logic, data, and compliance standards.'
                            />
                    </Box>
                    <LearnMoreBtn />
                    </Box>
                    <Box theme={theme} className='color-wrap pink' width={{ md: '50%', sm: '100%' }}>
                    <Typography theme={theme} variant='h2' component='h2' className='heading-sm' mb={1}>
                    Chat
                    </Typography>
                    <Typography theme={theme} variant='body1' component='p' className='section-text' mb={{ sm: 1, xs: 1 }}>
                    AI-powered conversational interface enabling real-time customer engagement and support
                    </Typography>
                    <Box theme={theme} display={{ md: 'block', sm: 'none', xs: 'none' }} mb={4}>
                        <LearnMoreLink />
                    </Box>
                    <Box theme={theme} className='bottom' mb={{ lg: 0, md: 1, sm: 1, xs: 1 }}>
                    <MultiColorCard
                                title='Contextual awareness &Tool Calling'
                                text='Chat that understands conversational context for personalized interactions while seamlessly integrating with internal and external APIs to execute tasks, enhancing efficiency and user experience.'
                            />
                            </Box>
                            <LearnMoreBtn />
                    </Box>
            </Box>
            {/* **************************************** */}
            <Box theme={theme} className='color-wrap orange'>
            <Box theme={theme} display={{ md: 'flex', sm: 'inline-block' }} justifyContent={'space-between'} alignItems={'center'} gap={2} mb={{ lg: 0, md: 1, sm: 1, xs: 1 }}>
            <Box theme={theme} width={{ md: '50%', sm: '100%' }}>
                <Typography theme={theme} variant='h2' component='h2' className='heading-sm' mb={{ sm: 1, xs: 1 }}>
                        Felx AI
                        </Typography>
                        <Typography theme={theme} variant='body1' component='p' className='section-text' mb={{ sm: 1, xs: 1 }}>
                        A flexible AI powered finance structure recommender powered by generative AI and Flex
                        </Typography>
                        <Box theme={theme} display={{ md: 'block', sm: 'none', xs: 'none' }}>
                            <LearnMoreLink />
                        </Box>
                        </Box>
                        <Box theme={theme} width={{ md: '50%', sm: '100%' }}>
                        <Box theme={theme} display={{ sm: 'flex', xs: 'inline-block' }} justifyContent={'space-between'} gap={1} mb={1}>
                    <Box theme={theme} className='bottom' width={{ md: '55%', sm: '50%', xs: '100%' }} mt={{ md: 11, sm: 0, xs: 0 }} position={'relative'} top={{ md: 8, sm: 0, xs: 0 }}>
                    <MultiColorCard
                                title='Stipulation Generation'
                                text='Automatically detects discrepancies and generates lender-specific stipulations, ensuring compliance and mitigating risk in credit approvals.'
                            />
                            </Box>
                            <Box theme={theme} width={{ md: '45%', sm: '50%', xs: '100%' }}>
                                <MultiColorCard
                                    title='Finance Structure Recommendation'
                                    text='Suggests optimal finance structures based on applicant data, risk metrics, and industry benchmarks to support well-informed credit decisions.'
                                />
                            </Box>
                    </Box>
                        </Box>
                        </Box>
                        <LearnMoreBtn />
                    </Box>
          </EcosystemSectionStyle>
  )
}
