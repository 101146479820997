import { styled } from '@mui/material/styles'
import { Box, type IBoxProps } from '@ntpkunity/controls'
import { CustomColors } from '@shared/theme'

export const MultiColorCardStyle = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.multi-color-card': {
    width: '100%',
    padding: 24,
    borderRadius: 16,
    backgroundColor: CustomColors.greyLight,
    color: theme.palette.grey[600],
    '.title': {
      paddingLeft: 24,
      '&:before': {
        content: "''",
        display: 'inline-block',
        backgroundColor: theme.palette.grey[600],
        width: 16,
        height: 16,
        borderRadius: 4,
        float: 'left',
        position: 'absolute',
        marginLeft: -24,
        marginTop: 3
      }
    },
    '&.purple-bg': {
      backgroundColor: CustomColors.purpleLight,
      color: CustomColors.purple,
      '.title:before': {
        backgroundColor: CustomColors.purple
      }
    },
    '&.green-bg': {
      backgroundColor: CustomColors.greenLight,
      color: CustomColors.green,
      '.title:before': {
        backgroundColor: CustomColors.green
      }
    },
    '&.orange-bg': {
      backgroundColor: CustomColors.orangeLight,
      color: CustomColors.orange,
      '.title:before': {
        backgroundColor: CustomColors.orange
      }
    },
    '&.pink-bg': {
      backgroundColor: CustomColors.pinkLight,
      color: CustomColors.pink,
      '.title:before': {
        backgroundColor: CustomColors.pink
      }
    }
  }
}))
