import { styled } from '@mui/material/styles'
import { Box, type IBoxProps } from '@ntpkunity/controls'
import BannerImage from '@shared/assets/images/ai-banner-bg.jpg'

export const BannerWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.ai-banner-wrap': {
    backgroundImage: `url(${BannerImage})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    width: '100%',
    minHeight: 680,
    borderRadius: 40,
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      backgroundPosition: 'left',
      minHeight: 530,
      textAlign: 'center'
    },
    '.ai-banner-content': {
      maxWidth: 565,
      [theme.breakpoints.up('sm')]: {
        '.text-h2': {
          fontSize: theme.typography.h1.fontSize,
          lineHeight: theme.typography.h1.lineHeight
        }
      },
      '@media(min-width: 650px) and (max-width: 960px)': {
        paddingTop: 70,
        maxWidth: 440,
        width: '100%'
      }
    }
  }
}))
