import { styled } from '@mui/material'
import { Box, type IBoxProps } from '@ntpkunity/controls'
import JourneySectionBg from '@shared/assets/images/journey-section-bg.png'

export const JourneySectionStyle = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.journey-section': {
    background: `url(${JourneySectionBg})` + 'fixed',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 1200,
    backgroundPosition: 'center center',
    backgroundColor: theme.palette.common.black,
    borderRadius: 24,
    width: '100%',
    padding: 55,
    position: 'relative',
    color: theme.palette.common.white,
    '.card': {
      minHeight: 170,
      backgroundColor: 'rgba(45, 39, 39, 0.85)',
      '.text': {
        color: theme.palette.common.white
      }
    },
    [theme.breakpoints.down('md')]: {
      backgroundSize: '110%',
      backgroundPosition: '-4px center',
      padding: 24
    },
    [theme.breakpoints.down('sm')]: {
      '.heading-h1': {
        fontSize: theme.typography.h3.fontSize,
        lineHeight: theme.typography.h3.lineHeight
      },
      '.heading-h2': {
        fontSize: theme.typography.h4.fontSize,
        lineHeight: theme.typography.h4.lineHeight
      },
      '.card': {
        minHeight: 'auto'
      }
    }
  }
}))
