import { type FC } from 'react'
import { Box, Button, Container, Typography } from '@ntpkunity/controls'
import { useTheme } from '@mui/material'
import { FadeAnimation } from '@shared/components'
import { BannerWrap } from './aiBannerStyle'
import { useNavigate } from 'react-router-dom'
import { APP_ROUTES } from '@router/path'

export const AiBanner: FC = () => {
  const theme = useTheme()
  const navigate = useNavigate()
  return (
    <BannerWrap theme={theme} className='ai-banner-wrap'>
      <Container theme={theme} className='custom-container' maxWidth={'lg'}>
            <FadeAnimation>
              <Box theme={theme} className='ai-banner-content'>
                <Typography theme={theme} className='text-h2' variant='h1' component='h1' mb={{ md: 4, sm: 1, xs: 1 }}>
                    Take a leap in your finance and leasing business with <span className='text-gradient'>AppexNow AI platform</span>
                </Typography>
                <Button theme={theme} primary text="Contact Us" onClick={() => { navigate(APP_ROUTES.CONTACT_US) }}/>
              </Box>
            </FadeAnimation>
      </Container>
    </BannerWrap>
  )
}
