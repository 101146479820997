import { styled } from '@mui/material'
import { Box, type IBoxProps } from '@ntpkunity/controls'

export const EcosystemSectionStyle = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.ecosystem-section': {
    width: '100%',
    padding: '120px 0',

    '.section-text': {
      color: theme.palette.grey[600]
    },

    [theme.breakpoints.down('sm')]: {
      padding: '40px 0',
      '.heading-lg': {
        fontSize: theme.typography.h3.fontSize,
        lineHeight: theme.typography.h3.lineHeight
      },
      '.heading-sm': {
        fontSize: theme.typography.h4.fontSize,
        lineHeight: theme.typography.h4.lineHeight
      }
    }
  }
}))
